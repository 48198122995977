import Routing from "src/routes";
import { FarmProvider } from "./context/FarmContext";
import { CartProvider } from "./context/CartContext";

const App = () => {
  return (
    <FarmProvider>
      <CartProvider>
        <Routing />
      </CartProvider>
    </FarmProvider>
  );
};

export default App;
