import { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import ReactJoyride from "react-joyride";

const steps = [
  {
    target: ".paynow-btn",
    content:
      "Enter your payment details to complete your purchase. You can expect your good delivered to you in a few days.",
    disableBeacon: true,
  },
];

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    const { paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",

      confirmParams: {
        return_url: `${window.location.origin}/success`,
      },
    });

    navigate({
      pathname: "/success",
      search: `?paymentIntent=${paymentIntent?.id}&amount=${paymentIntent?.amount}`,
    });

    if (!paymentIntent) {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (["skipped"].includes(status)) {
      window.localStorage.removeItem("isTourRunning");
    }
  };

  return (
    <>
      {window.localStorage.getItem("isTourRunning") && (
        <ReactJoyride
          steps={steps}
          showProgress={false}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          disableScrolling={true}
          spotlightPadding={10}
          continuous={true}
          locale={{ last: "Understood", skip: "Got It" }}
        />
      )}
      <form
        id="payment-form"
        className="flex flex-col gap-4 mt-10"
        onSubmit={handleSubmit}
      >
        <PaymentElement id="payment-element" />

        <button
          className="btn bg-[#097435] border-none normal-case rounded-full h-[40px] w-[180px] gap-3 paynow-btn"
          disabled={isProcessing || !stripe || !elements}
          id="submit"
        >
          <span id="button-text" className="text-white">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>

        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
