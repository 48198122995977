const About = ({ data }) => {
  return (
    <div className="pt-[60px] flex flex-col gap-4">
      <div className="flex">
        <h2 className="text-[24px] font-bold text-[#262D33]">
          ABOUT THIS FARMER
        </h2>
      </div>
      <div className="flex">
        <p className="text-[16px] text-[#262D33]">{data?.description || "-"}</p>
      </div>
      <div className="pt-[20px]">
        <hr />
      </div>
    </div>
  );
};

export default About;
