/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { BASE_URL } from "src/constants";
import { request } from "src/request";

const ManufactureDetail = ({ product }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [farmer, setFarmer] = useState(false);
  console.log("🚀 ~ file: index.jsx:9 ~ ManufactureDetail ~ farmer:", farmer);

  useEffect(() => {
    getFarmer();
  }, []);

  const getFarmer = async () => {
    try {
      setIsLoading(true);
      const response = await request("GET", `/v1/users/${product?.createdBy}`);
      setFarmer(response);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const Description = ({ title, firstPara, secondPara, image }) => (
    <div className="flex flex-col gap-6">
      <h3 className="font-bold text-[#262D33] text-sm">{title}</h3>
      <div className="flex gap-8">
        {image && (
          <img
            src={image}
            class="rounded-lg md:w-48 max-w-xs"
            alt="product-img"
          />
        )}

        <div>
          <p className="text=[#4B5157] text-justify">{firstPara}</p>

          {secondPara && (
            <p className="mt-4 text=[#4B5157] text-justify">{secondPara}</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="py-8 flex flex-col gap-8">
        <div className="flex">
          <h2 className="font-bold text-[#262D33] text-xl ml-5">
            From Manufacture
          </h2>
        </div>

        <div className="flex flex-col items-center  md:flex-row md:max-w-full ml-5">
          <Description
            image={
              product.images[0].includes("http://") ||
              product.images[0].includes("https://")
                ? product.images[0]
                : `${BASE_URL}/v1/documents/product-img/${product.images[0]}` ||
                  "https://via.placeholder.com/350x150"
            }
            title="Product Discription"
          />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <Description firstPara={product?.description} secondPara="" />
          </div>
        </div>
        {isLoading ? (
          "Loading..."
        ) : (
          <div className="flex flex-col items-center  md:flex-row md:max-w-full ml-5">
            <Description
              image={
                farmer?.profPic
                  ? `${BASE_URL}/v1/documents/prof-pic/${farmer?.profPic}`
                  : `https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png`
              }
              title="About Us"
            />
            <div className="flex flex-col justify-between p-4 leading-normal">
              <Description
                firstPara={`${farmer?.firstName} ${farmer?.lastName}`}
                secondPara=""
              />
            </div>
          </div>
        )}
      </div>
      <hr class="mt-4" />
    </>
  );
};

export default ManufactureDetail;
