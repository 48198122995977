/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Banner, Contact, About, Filter } from "src/components";
import { FilterCard } from "src/components/Cards";
import { request } from "src/request";
import Joyride from "react-joyride";

const steps = [
  {
    target: ".product-item",
    content:
      "Here is a listing of what the farmer has to offer. See something you like? Go ahead and add it to your cart.",
    disableBeacon: true,
  },
  {
    target: ".cart-button",
    content: "Please click cart button to continue with the purchase",
    disableBeacon: true,
  },
];

const FarmerPage = () => {
  const { farmId } = useParams();
  const [farmer, setFarmer] = useState({});
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  console.log(
    "🚀 ~ file: index.jsx:30 ~ FarmerPage ~ filteredProducts:",
    filteredProducts
  );
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFarmer();
  }, []);

  useEffect(() => {
    getFarmerProducts();
  }, []);

  useEffect(() => {
    if (products?.length > 0) {
      const categories = products?.map((product) => product.category);
      setCategories(categories);
    }
  }, [products]);

  const getFarmer = async () => {
    try {
      setIsLoading(true);
      const response = await request("GET", `/v1/users/${farmId}`);
      setFarmer(response);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getFarmerProducts = async () => {
    try {
      setIsLoading(true);

      const response = await request(
        "GET",
        `/v1/products?status=Active&sortBy=createdAt:desc&limit=100&createdBy=${farmId}`
      );

      setProducts(response?.results);
      setFilteredProducts(response?.results);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  };

  const handleSearch = (keyword) => {
    if (keyword == "") return setFilteredProducts(products);
    const _products = products?.filter((prdct) => {
      if (String(prdct?.name)?.toLowerCase().includes(keyword.toLowerCase()))
        return prdct;
    });
    setFilteredProducts(_products);
  };

  const handleFilter = (category) => {
    if (category == "All") return setFilteredProducts(products);
    const _products = products?.filter((prdct) => {
      if (prdct?.category?.name == category) return prdct;
    });
    setFilteredProducts(_products);
  };

  const ResultList = () => {
    const handleJoyrideCallback = (data) => {
      const { status } = data;

      if (["skipped"].includes(status)) {
        window.localStorage.removeItem("isTourRunning");
      }
    };

    return (
      <>
        {!isLoading && window.localStorage.getItem("isTourRunning") && (
          <Joyride
            run={!isLoading}
            steps={steps}
            showProgress={false}
            showSkipButton={true}
            callback={handleJoyrideCallback}
            disableCloseOnEsc={true}
            disableOverlayClose={true}
            disableScrolling={true}
            spotlightPadding={10}
            continuous={true}
            locale={{ last: "Understood", skip: "Got It" }}
          />
        )}

        <div className="flex flex-col gap-8 py-6 overflow-auto max-h-[50rem] product-item">
          {filteredProducts?.length > 0 ? (
            filteredProducts?.map(
              (
                {
                  id,
                  images,
                  name,
                  weight,
                  hasPromotion,
                  unitType,
                  price,
                  description,
                  ...rest
                },
                index
              ) => (
                <div key={index}>
                  <FilterCard
                    image={images[0]}
                    name={name}
                    weight={weight}
                    unitType={unitType}
                    price={price}
                    hasPromotion={hasPromotion}
                    description={description}
                    farm={farmer?.farm}
                    id={id}
                    product={{
                      ...rest,
                      id,
                      images,
                      name,
                      weight,
                      unitType,
                      price,
                      description,
                    }}
                  />
                </div>
              )
            )
          ) : (
            <p>No products found</p>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Banner data={farmer} loading={isLoading} />
      <div className="container">
        <About data={farmer} />
        <Filter
          onSearch={handleSearch}
          categories={categories}
          onFilter={handleFilter}
        />
        <ResultList />
        <Contact />
      </div>
    </>
  );
};

export default FarmerPage;
